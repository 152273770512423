<template>
    <div class="modal-backdrop">
        <div class="modal-container" v-if="showDeleteModal">
            <div class="data-container">
                <button class="close-button" @click="closeDeleteConfirmation">&#10006;</button>
                <div class="info">
                    <h4 class="info-para"> {{ $t("Delete Account?") }}</h4>
                    <p>{{ $t("Are you sure you want to delete your account?") }}</p>
                    <p>{{ $t("This will permanently erase your account") }}</p>
                    <p>{{ $t("This action cannot be undone") }}</p>
                </div>
                <div class="action-buttons">
                    <button class="confirm-button" @click="handleDeleteAccount">{{ $t("DELETE FOREVER") }}</button>
                    <button class="cancel-button" @click="closeDeleteConfirmation">{{ $t("Cancel") }}</button>
                </div>
                <Loading v-if="isLoaderActive"></Loading>
            </div>
        </div>
        <div v-if="updateOTPSuccess" class="profile-popup" slot="component">
            <button class="close-button" @click="handleSuccessDone">&#10006;</button>
            <div class="center success-img">
                <img src="./../../../assets/icons/approval.svg" alt="Success" />
            </div>
            <p class="profile-popup-title center sub-title">{{ $t("Successful") }}</p>
            <p class="profile-popup-title center">{{ $t("Your account is deleted successfully") }}</p>
            <button type="button" @click="handleSuccessDone" class="button-primary profile-popup-btn primary-net5-button">{{
                $t("DONE") }}</button>

        </div>
        <OtpModal id="pc-otp-modal" :open="showOtpModal" @onClose="closeOtpModal"
            @onActionComplete="onOtpModalActionComplete" @onResend="resendOtp" :pinError="pinModalError"
            :loading="loading" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import { showSnackBar } from "@/utilities";
import { mapGetters } from 'vuex';
import Utility from "@/mixins/Utility.js";


export default {
    data() {
        return {
            showOtpModal: false,
            otpModalError: "",
            pinModalError:"",
            showDeleteModal: true,
            updateOTPSuccess: false,
            localDisplayLang: null,
            loading: false,
            isLoaderActive: false,
        }
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    },
    computed: {
        ...mapGetters([
            "subscriberid",
            "profileid",
        ]),
    },
    methods: {
        ...mapActions(["deleteAccount", "deleteUserApi", "actGetLogout"]),


        // DeleteForevere Method will call and will get otp 

        handleDeleteAccount() {
            let payload = {
                otp_type: "deleteAccount",
            }
            this.isLoaderActive = true;
            this.deleteAccount(payload)
                .then((data) => {
                    if (data.data.reason) {
                        showSnackBar(this.$t(data.data.reason),this.$t('DISMISS'));
                        this.otpModalError = this.$t(data.data.reason);
                        this.$emit("closePopup");
                        showSnackBar(this.$t("Sorry! we can't delete your account, because you have an active subscription. Please cancel that and try to delete"), this.$t('DISMISS'));
                        // this.isLoaderActive = false;
                        return;
                    }
                    else if (data.data.success) {
                        this.isLoaderActive = false;
                        this.showDeleteModal = false;
                        this.showOtpModal = true;
                    }
                })
                .catch((error) => {
                    console.log(" deleteAccounterror -> ", error);
                });
        },



        resendOtp() {
            this.loading = true;
            let payload = {
                otp_type: "deleteAccount",
            };
            this.deleteAccount(payload)
                .then((data) => {
                    this.loading = false;
                    if (data.data.reason) {
                        this.pinModalError = this.$t(data.data.reason);
                        return;
                    } else if (data.data.success) {
                        console.log("Controldelete", data);
                    }
                })
                .catch((error) => {
                    console.log("Controldelete error -> ", error);
                });
        },

        //Otp validation and  trigger profile deletion
        onOtpModalActionComplete(enteredPin) {
            this.pinModalError = "";
            this.loading = true;
            let payload = {
                otp: enteredPin,
            };
            this.deleteUserApi(payload)
                .then((data) => {
                    this.loading = false;
                    if (data.data.reason) {
                        this.pinModalError = this.$t(data.data.reason);
                        return;
                    }
                    this.showOtpModal = false;
                    setTimeout(() => {
                        this.updateOTPSuccess = true;
                        document.body.style.overflow = "hidden";
                    }, 150);
                })
                .catch((error) => {
                    console.log("deleteAccountVerify error -> ", error);
                });
        },


        logout() {
            eventBus.$emit("subscriberLogout");
        },
        closeOtpModal() {
            this.showOtpModal = false;
            this.closeDeleteConfirmation();
        },
        closeDeleteConfirmation() {
            this.$emit("closePopup");
        },
        closePopup() {
            this.updateOTPSuccess = false; // Close the success popup
        },

        handleSuccessDone() {
            console.log("getlang", currentLanguage)
            this.logout();
            let currentLanguage = this.getCurrentLanguageUrlBase();
            this.$router.push({ name: "Home", params: { lang: currentLanguage } });


        },
    },

    components: {
        OtpModal: () => import("@/components/utils/OtpModal.vue"),
        Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),

    },
    mixins: [Utility],
}

</script>
<style lang="scss" scoped>
//success

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    z-index: 9999;
}

.profile-popup {
    width: 400px;
    background-color: #1a1a1a;
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}


.profile-popup .center {
    text-align: center;
}

.profile-popup img {
    width: 80px;
    height: 80px;
}

.profile-popup p {
    color: white;
    font-size: 18px;
    margin: 10px 0;
}



//success
.modal-container {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: rgba(0, 0, 0, 0.7);
    z-index: 8888;
}

.data-container {
    position: relative;
    width: 60%;
    max-width: 400px;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info {
    color: aliceblue;
    text-align: center;
    margin-bottom: 0.9rem;
}

.info-para {
    padding: 0.9rem;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    justify-content: center;
    margin-top: 20px;

    button {
        width: 45%;
        height: 40px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: 14px;
        text-transform: uppercase;
    }

    .confirm-button {
        background:linear-gradient(to right, #9000B9, #EA00FF,#9000B9);
        color: white;

        &:hover {
            background: #fff;
            color: #EA00FF;
        }
    }

    .cancel-button {
        background:linear-gradient(to right, #9000B9, #EA00FF,#9000B9);
        color: white;

        &:hover {
            background: #fff;
            color: #EA00FF;
        }
    }
}

@media (max-width: 480px) {
    .data-container {
        width: 95%;
    }

    .profile-popup {
        width: 95%;
    }
}
</style>
